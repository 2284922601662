Bu sayfadaki puf noktalar:
1 - Bos
<template>
    <div>
        <Navbar />
        asdasd
    </div>
</template>

<script>
import Navbar from "@/components/Navbar"

export default {
    components : {
        Navbar
    }
}
</script>

<style scoped>

</style>